
// api
export const DO_GET_NOTIFICATION_MASTER = "DO_GET_NOTIFICATION_MASTER";
export const DO_GET_NOTIFICATION_MASTER_SUCCESS = "DO_GET_NOTIFICATION_MASTER_SUCCESS";
export const DO_GET_NOTIFICATION_MASTER_FAILED = "DO_GET_NOTIFICATION_MASTER_FAILED";

export const DO_PATCH_NOTIFICATION_MASTER = "DO_PATCH_NOTIFICATION_MASTER";
export const DO_PATCH_NOTIFICATION_MASTER_SUCCESS = "DO_PATCH_NOTIFICATION_MASTER_SUCCESS";
export const DO_PATCH_NOTIFICATION_MASTER_FAILED = "DO_PATCH_NOTIFICATION_MASTER_FAILED";

const ANRAKUTEI = "00000001";
const ANRAKUTEI_FC = "00000002";
const SHICHIRINBO = "00000003";

export const BUSINESS_TYPES = [
     {
          code: ANRAKUTEI,
          name: '安楽亭 焼肉',
          content: ''
     },
     {
          code: ANRAKUTEI_FC,
          name: '暖簾安楽亭',
          content: ''
     },
     {
          code: SHICHIRINBO,
          name: '七輪房',
          content: ''
     },

];